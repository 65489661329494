import { NavLink } from 'react-router-dom';

export default function NotFound() {
    return (
        <div className="min-h-screen flex flex-col items-center py-20">
            <div className="p-3 text-sm font-medium text-sky-500 rounded-full bg-sky-50 inline-flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
            </div>

            <div className="w-3/4 px-2 lg:w-1/2 mt-4">
                <div className="py-2 text-xl font-medium text-[#686868] font-metropolis text-center">
                    <p className="mb-5">
                        Page not found. To go to my home page, click <NavLink to="/"
                            className="font-semibold hover:underline text-sky-600"
                        >here</NavLink>.
                    </p>
                </div>
            </div>
        </div>
    );
};
