import { NavLink } from 'react-router-dom';

export default function Blog() {
    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-3/4 px-2 lg:w-1/2">
                <div className="py-20 text-xl font-medium text-[#686868] font-metropolis lg:py-44">
                    <p className="mb-5">
                        My interests are in robotics, neural rendering, machine
                        learning, and computer graphics. To go to my home page,
                        click <NavLink to="/"
                            className="font-semibold hover:underline text-sky-600"
                        >here</NavLink>.
                    </p>
                    <ul className="pl-4 mb-10 list-disc">
                        <li className="mb-2">
                            <NavLink
                                to="/writing/NeRFvs3DGS"
                                className="font-semibold hover:underline text-sky-600"
                            >Neural Radiance Fields vs. 3D Gaussian Splatting
                            </NavLink> (Feb. 2022)
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
