import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Blog from './pages/blog';
import Landing from './pages/landing';
import Loading from './pages/loading';
import NotFound from './pages/404';
import ScrollToTop from './components/ScrollToTop';

const Before2022 = React.lazy(() => import('./pages/before2022'));
const NeRFvs3DGS = React.lazy(() => import('./pages/posts/2024_02'));

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route exact path="/" element={<Landing />} />
                    <Route exact path="/before2022" element={<Before2022 />} />
                    <Route exact path="/writing" element={<Blog />} />
                    <Route exact path="/writing/NeRFvs3DGS" element={<NeRFvs3DGS />} />
                    <Route path="*" element={<NotFound/>} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
