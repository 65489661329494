import { NavLink } from 'react-router-dom';
import Hyperlink from '../components/Hyperlink';

export default function Landing() {
    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-3/4 px-2 lg:w-1/2">
                <div className="py-20 text-xl font-medium text-[#686868] font-metropolis lg:py-44">
                    <p className="mb-5">
                        <b className="pr-2 font-bold">Hi! I'm Edward.</b>
                        I founded <Hyperlink
                            text="InPerson"
                            link="https://inperson.design"
                            color="text-sky-600"
                        />, a startup building 3D telepresence. Before
                        that, I built character AI at Apple's Vision Pro division
                        and completed a thesis at Carnegie Mellon University on
                        safe reinforcement learning methods for robotics.
                    </p>
                    <p className="mb-10">
                        I love playing volleyball, using 3D technology, and
                        building robots. I'm also a huge fan of <Hyperlink
                            text="Mission burritos"
                            link="https://whohasthebestmissionburrito.com/"
                            color="text-sky-600"
                        />.
                    </p>
                    <p className="mb-2 font-bold">To learn more:</p>
                    <ul className="pl-4 mb-10 list-disc">
                        <li className="mb-2">My <NavLink
                            to="/writing"
                            className="font-semibold text-sky-600 hover:underline"
                        >blog</NavLink>, where I write about technology</li>
                        <li className="mb-2"><Hyperlink
                            text="Coral"
                            link="https://coral3d.app/"
                            color="text-sky-600"
                        />, the precursor to InPerson</li>
                        <li className="mb-2">A <Hyperlink
                            text="recollection"
                            link="https://coral3d.app/other"
                            color="text-sky-600"
                        /> of my 2022 sabbatical</li>
                        <li className="mb-2">My previous <NavLink
                            to="/before2022"
                            className="font-semibold text-sky-600 hover:underline"
                        >website</NavLink> outlining my life before 2022</li>
                    </ul>
                    <p className="mb-2 font-bold">Let's connect!</p>
                    <ul className="pl-4 list-disc">
                        <li className="mb-2"><Hyperlink
                            text="Email"
                            link="mailto:edward@edwardahn.me"
                            color="text-sky-600"
                        /> me</li>
                        <li className="mb-2">Follow my <Hyperlink
                            text="Twitter"
                            link="https://twitter.com/edwardahn9"
                            color="text-sky-600"
                        /></li>
                        <li className="mb-2">Add me on <Hyperlink
                            text="LinkedIn"
                            link="https://www.linkedin.com/in/edwardsahn"
                            color="text-sky-600"
                        /></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
